import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';
import ArticleList from '../../organisms/ArticleList';

import Breadcrumb from '../../molecules/common/Breadcrumb';
import MainHeading from '../../molecules/common/MainHeading';
import TotalBadge from '../../molecules/common/TotalBadge';
import Pagination from '../../molecules/common/Pagination';

import * as styles from './index.module.css';

const CategoryTemplate = ({ pageContext }) => {
  const { pagePath, category, pagination } = pageContext;

  const breadcrumbItems = [
    {
      to: '/',
      label: 'ホーム',
    },
    {
      to: '/category',
      label: 'カテゴリー一覧',
    },
    {
      to: `/category/${category.slug}`,
      label: category.name,
      partiallyActive: true,
    },
  ];

  return (
    <PublicRoot>
      <SEO
        path={pagePath}
        title={category.name}
        description={category.description.description}
      />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <nav className={styles['nav']}>
              <ContainerDefault>
                <Breadcrumb items={breadcrumbItems} />
              </ContainerDefault>
            </nav>

            <section className={styles['section']}>
              <ContainerDefault>
                <div className={styles['headingWrapper']}>
                  <InlineBlockWrapper className={styles['mainHeadingWrapper']}>
                    <MainHeading heading={category.name} />
                  </InlineBlockWrapper>
                  <InlineBlockWrapper className={styles['totalBadgeWrapper']}>
                    <TotalBadge total={pagination.total} />
                  </InlineBlockWrapper>
                </div>

                <div className={styles['articleListWrapper']}>
                  <ArticleList articles={category.articles} />
                </div>

                {pagination.pageCount > 1 && (
                  <div className={styles['paginationWrapper']}>
                    <Pagination
                      pagination={pagination}
                      basePath={`/category/${category.slug}`}
                    />
                  </div>
                )}
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default CategoryTemplate;
